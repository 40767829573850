import { InputField } from "./Common/InputField";
import { Button } from "./Common/Button";
import React from "react";

export const Contact = () => {
  return (
    <div className="">
      <div className="mx-auto lg:container lg:px-16 xl:px-20 h-full w-full -mt-16">
        <div className="grid grid-cols-3 gap-8">
          <div className="h-32 bg-background rounded-lg w-full flex items-center justify-center gap-6">
            <div className="rounded-full h-14 w-14 flex items-center justify-center bg-primary">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-6 h-6 text-background"
              >
                <path
                  fillRule="evenodd"
                  d="M1.5 4.5a3 3 0 0 1 3-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 0 1-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 0 0 6.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 0 1 1.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 0 1-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5Z"
                  clipRule="evenodd"
                />
              </svg>
            </div>

            <div className="flex flex-col">
              <div className="font-semibold uppercase tracking-wider text-white">
                Call Now
              </div>
              <div className="font-bold text-2xl uppercase tracking-wider text-white">
                +61 03 9798 7786
              </div>
            </div>
          </div>

          <div className="h-32 bg-primary rounded-lg w-full flex items-center justify-center gap-6">
            <div className="rounded-full h-14 w-14 flex items-center justify-center bg-background">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-6 h-6 text-primary"
              >
                <path
                  fillRule="evenodd"
                  d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25ZM12.75 6a.75.75 0 0 0-1.5 0v6c0 .414.336.75.75.75h4.5a.75.75 0 0 0 0-1.5h-3.75V6Z"
                  clipRule="evenodd"
                />
              </svg>
            </div>

            <div className="flex flex-col">
              <div className="font-semibold uppercase tracking-wider text-background">
                Operating Hours
              </div>
              <div className="font-bold text-2xl uppercase tracking-wider text-background">
                9:00AM - 5:00PM AEDT
              </div>
            </div>
          </div>

          <div className="h-32 bg-background rounded-lg w-full flex items-center justify-center gap-6">
            <div className="rounded-full h-14 w-14 flex items-center justify-center bg-primary">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-6 h-6 text-background"
              >
                <path d="M1.5 8.67v8.58a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3V8.67l-8.928 5.493a3 3 0 0 1-3.144 0L1.5 8.67Z" />
                <path d="M22.5 6.908V6.75a3 3 0 0 0-3-3h-15a3 3 0 0 0-3 3v.158l9.714 5.978a1.5 1.5 0 0 0 1.572 0L22.5 6.908Z" />
              </svg>
            </div>

            <div className="flex flex-col">
              <div className="font-semibold uppercase tracking-wider text-white">
                Email Us
              </div>
              <div className="font-bold text-2xl uppercase tracking-wider text-white">
                sales@wpfusion.com
              </div>
            </div>
          </div>
        </div>

        <div className="py-24 grid grid-cols-2 gap-12">
          <div className="flex flex-col">
            <div className="tracking-widest text-primary uppercase font-bold text-lg">
              Get In Touch
            </div>

            <div className="text-background font-bold text-6xl mb-5">
              Send Us a <span className="text-primary">Message</span>
            </div>

            <div className="space-y-3">
              <div className="grid grid-cols-2 gap-4">
                <InputField label="First Name" placeholder="John" />
                <InputField label="Last Name" placeholder="Doe" />
              </div>

              <div className="grid grid-cols-2 gap-4">
                <InputField label="Your Email" placeholder="john@doe.com" />
                <InputField label="Your Number" placeholder="+6123456789" />
              </div>

              <InputField
                label="Your Message"
                type="textarea"
                rows={8}
                placeholder="Type your message here..."
              />

              <Button
                className="!font-semibold"
                buttonStyle="primary"
                label="Send Message"
                size="large"
                rightIcon
                icon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      fillRule="evenodd"
                      d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm4.28 10.28a.75.75 0 0 0 0-1.06l-3-3a.75.75 0 1 0-1.06 1.06l1.72 1.72H8.25a.75.75 0 0 0 0 1.5h5.69l-1.72 1.72a.75.75 0 1 0 1.06 1.06l3-3Z"
                      clipRule="evenodd"
                    />
                  </svg>
                }
              />
            </div>
          </div>

          <div>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3138.5088138146702!2d145.35957772740727!3d-38.12836019921915!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad604dbd75482b9%3A0xd09f4ceb4b28084c!2s34%20Sicily%20Rd%2C%20Clyde%20VIC%203978!5e0!3m2!1sen!2sau!4v1712552154484!5m2!1sen!2sau"
              width="100%"
              height="100%"
              title="Address location"
              allowfullscreen={false}
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
              className="border-0"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};
