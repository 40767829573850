import { Button } from "../components/Common/Button";
import { Logo } from "../components/Common/Logo";
import { Contact } from "../components/Contact";
import { FooterLink } from "../components/FooterLink";
import machineImage from "../assets/machine.png";

export const Home = () => {
  return (
    <div>
      <div className="h-[calc(100vh-5rem)] w-full bg-background hero-image">
        <div className="bg-background/80 h-full w-full">
          <div className="mx-auto lg:container lg:px-16 xl:px-20 h-full w-full">
            <div className="flex items-center justify-between h-full w-full gap-20">
              <div className="flex flex-col gap-5">
                <div className="font-bold tracking-widest text-lg uppercase text-primary">
                  Welcome to World Polymer Fusion
                </div>
                <div className="font-bold text-8xl text-white mb-2">
                  The Poly Welding Specialists
                </div>
                <div className="font-medium text-white mb-5">
                  Australian engineered poly welding machinery for the harshest
                  conditions.
                </div>
                <div>
                  <Button
                    className="!font-semibold"
                    buttonStyle="primary"
                    label="Book an Appointment"
                    size="large"
                    rightIcon
                    icon={
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          fillRule="evenodd"
                          d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm4.28 10.28a.75.75 0 0 0 0-1.06l-3-3a.75.75 0 1 0-1.06 1.06l1.72 1.72H8.25a.75.75 0 0 0 0 1.5h5.69l-1.72 1.72a.75.75 0 1 0 1.06 1.06l3-3Z"
                          clipRule="evenodd"
                        />
                      </svg>
                    }
                  />
                </div>
              </div>
              <div>
                <img src={machineImage} />
              </div>
            </div>

            <div className="h-32 -mt-16 w-full rounded-lg bg-primary px-10">
              <div className="grid grid-cols-6 items-center justify-center gap-14 opacity-50 w-full h-full">
                <img src="https://kfkit.rometheme.pro/built/wp-content/uploads/sites/73/2023/11/Client-2.png" />
                <img src="https://kfkit.rometheme.pro/built/wp-content/uploads/sites/73/2023/11/Client-1.png" />
                <img src="https://kfkit.rometheme.pro/built/wp-content/uploads/sites/73/2023/11/Client-3.png" />
                <img src="https://kfkit.rometheme.pro/built/wp-content/uploads/sites/73/2023/11/Client-4.png" />
                <img src="https://kfkit.rometheme.pro/built/wp-content/uploads/sites/73/2023/11/Client-5.png" />
                <img src="https://kfkit.rometheme.pro/built/wp-content/uploads/sites/73/2023/11/Client-6.png" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mx-auto lg:container lg:px-16 xl:px-20 h-full w-full py-40">
        <div className="grid grid-cols-2 items-center">
          <div className="relative">
            <img
              src="https://kfkit.rometheme.pro/built/wp-content/uploads/sites/73/2023/11/salesman-at-the-shop-with-heavy-machinery-DNSRH66.jpg"
              className="home-image rounded-lg"
            />
            <div className="absolute left-0 bottom-0 p-5 bg-primary rounded-tr-lg flex flex-col items-center justify-center ml-3 text-background">
              <div className="text-4xl font-bold">38+</div>
              <div className="uppercase font-bold text-sm">
                years of experience
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-4">
            <div className="font-bold tracking-widest text-lg uppercase text-primary">
              WHY CHOOSE US
            </div>
            <div className="font-bold text-6xl text-background">
              What Makes WP Fusion Your Ideal Partner
            </div>
            <div className="text-gray-600">
              With over 38 years of industrial experience and a legacy of
              innovation, WP Fusion is your trusted partner for cutting-edge
              polymer welding solutions.
            </div>

            <div className="rounded-lg bg-primary flex p-5 text-background gap-3 mt-3">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-9 h-9"
                >
                  <path
                    fillRule="evenodd"
                    d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>

              <div className="flex flex-col gap-1">
                <div className="font-bold text-2xl">
                  Product Development and Innovation
                </div>
                <div>
                  Utilize insights from market research to develop innovative
                  polymer welding equipment solutions that address customer
                  needs effectively.
                </div>
              </div>
            </div>

            <div className="rounded-lg bg-background flex p-5 text-primary gap-3">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-9 h-9"
                >
                  <path
                    fillRule="evenodd"
                    d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>

              <div className="flex flex-col gap-1">
                <div className="font-bold text-2xl">
                  Timely Delivery and Support Services
                </div>
                <div>
                  Commit to delivering products on time and prodivde efficient
                  after-sales support services. Offer technical assistance,
                  maintenance and troubleshooting.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mx-auto lg:container lg:px-16 xl:px-20 h-full w-full pb-40">
        <div className="grid grid-cols-2 items-center">
          <div className="flex flex-col gap-4">
            <div className="font-bold tracking-widest text-lg uppercase text-primary">
              What We Offer
            </div>
            <div className="font-bold text-6xl text-background">
              Experience Efficiency & Reliability with Every Purchase
            </div>
            <div className="text-gray-600">
              With over 38 years of industrial experience and a legacy of
              innovation, WP Fusion is your trusted partner for cutting-edge
              polymer welding solutions.
            </div>

            <div className="rounded-lg bg-primary flex p-5 text-background gap-3 mt-3">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-9 h-9"
                >
                  <path
                    fillRule="evenodd"
                    d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>

              <div className="flex flex-col gap-1">
                <div className="font-bold text-2xl">
                  Product Development and Innovation
                </div>
                <div>
                  Utilize insights from market research to develop innovative
                  polymer welding equipment solutions that address customer
                  needs effectively.
                </div>
              </div>
            </div>

            <div className="rounded-lg bg-background flex p-5 text-primary gap-3">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-9 h-9"
                >
                  <path
                    fillRule="evenodd"
                    d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>

              <div className="flex flex-col gap-1">
                <div className="font-bold text-2xl">
                  Timely Delivery and Support Services
                </div>
                <div>
                  Commit to delivering products on time and prodivde efficient
                  after-sales support services. Offer technical assistance,
                  maintenance and troubleshooting.
                </div>
              </div>
            </div>
          </div>

          <div className="relative">
            <img
              src="https://kfkit.rometheme.pro/built/wp-content/uploads/sites/73/2023/11/salesman-at-the-shop-with-heavy-machinery-DNSRH66.jpg"
              className="home-image rounded-lg float-right"
            />
            <div className="absolute left-28 bottom-0 p-5 bg-primary rounded-tr-lg flex flex-col items-center justify-center ml-3 text-background">
              <div className="text-4xl font-bold">38+</div>
              <div className="uppercase font-bold text-sm">
                years of experience
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="home-quote-section">
        <div className="bg-background/90 h-full w-full">
          <div className="mx-auto lg:container lg:px-16 xl:px-20 h-full w-full py-40 text-center capitalize">
            <div className="text-primary font-bold tracking-widest uppercase mb-5">
              Get a Quote
            </div>

            <div className="text-white font-bold text-5xl">
              Are you prepared to{" "}
              <span className="text-primary">get started</span>?
            </div>
            <div className="text-white font-bold text-5xl mb-10">
              Get a quote right now!
            </div>

            <Button
              className="!font-semibold"
              buttonStyle="primary"
              label="Get Started"
              size="large"
              rightIcon
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    fillRule="evenodd"
                    d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm4.28 10.28a.75.75 0 0 0 0-1.06l-3-3a.75.75 0 1 0-1.06 1.06l1.72 1.72H8.25a.75.75 0 0 0 0 1.5h5.69l-1.72 1.72a.75.75 0 1 0 1.06 1.06l3-3Z"
                    clipRule="evenodd"
                  />
                </svg>
              }
            />
          </div>
        </div>
      </div>

      <Contact />

      <div className="bg-background">
        <div className="mx-auto lg:container lg:px-16 xl:px-20 h-full w-full flex flex-col">
          <div className="grid grid-cols-5 gap-14 py-10">
            <div className="flex flex-col gap-3 col-span-2">
              <Logo className="h-10" />

              <div className="text-white">
                With over 38 years of industrial experience and a legacy of
                innovation, WP Fusion is your trusted partner for cutting-edge
                polymer welding solutions.
              </div>
            </div>

            <div className="flex flex-col gap-2">
              <div className="text-white font-bold text-2xl">Quick Links</div>

              <FooterLink text="About" />
              <FooterLink text="Product Selector" />
              <FooterLink text="Contact Us" />
            </div>

            <div className="flex flex-col gap-2">
              <div className="text-white font-bold text-2xl">Social Media</div>

              <FooterLink text="LinkedIn" />
              <FooterLink text="Facebook" />
              <FooterLink text="Instagram" />
              <FooterLink text="Twitter" />
            </div>

            <div className="flex flex-col gap-2">
              <div className="text-white font-bold text-2xl">Contact</div>

              <div className="flex items-center gap-1.5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-5 h-5 text-primary"
                >
                  <path
                    fill-rule="evenodd"
                    d="M1.5 4.5a3 3 0 0 1 3-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 0 1-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 0 0 6.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 0 1 1.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 0 1-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5Z"
                    clip-rule="evenodd"
                  />
                </svg>

                <div className="text-white font-medium">+61 03 9798 7786</div>
              </div>

              <div className="flex items-center gap-1.5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-5 h-5 text-primary"
                >
                  <path d="M1.5 8.67v8.58a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3V8.67l-8.928 5.493a3 3 0 0 1-3.144 0L1.5 8.67Z" />
                  <path d="M22.5 6.908V6.75a3 3 0 0 0-3-3h-15a3 3 0 0 0-3 3v.158l9.714 5.978a1.5 1.5 0 0 0 1.572 0L22.5 6.908Z" />
                </svg>

                <div className="text-white font-medium">sales@wpfusion.com</div>
              </div>

              <div className="flex items-center gap-1.5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  class="w-5 h-5 text-primary"
                >
                  <path
                    fill-rule="evenodd"
                    d="m11.54 22.351.07.04.028.016a.76.76 0 0 0 .723 0l.028-.015.071-.041a16.975 16.975 0 0 0 1.144-.742 19.58 19.58 0 0 0 2.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 0 0-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 0 0 2.682 2.282 16.975 16.975 0 0 0 1.145.742ZM12 13.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
                    clip-rule="evenodd"
                  />
                </svg>

                <div className="text-white font-medium">
                  34 Sicily Road, Clyde VIC 3978 AU
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-between py-5 border-t border-white/10">
            <div className="text-white font-semibold">Privacy Policy</div>
            <div className="text-primary font-semibold">
              Copright &copy; 2024 | All Rights Reserved World Polymer Fusion
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
