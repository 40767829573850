import { Button } from "../components/Common/Button";
import { Logo } from "../components/Common/Logo";
import { Contact } from "../components/Contact";
import { FooterLink } from "../components/FooterLink";

export const ContactPage = () => {
  return (
    <div>
      <div className="pt-32">
        <Contact />
      </div>

      <div className="bg-background">
        <div className="mx-auto lg:container lg:px-16 xl:px-20 h-full w-full flex flex-col">
          <div className="grid grid-cols-5 gap-14 py-10">
            <div className="flex flex-col gap-3 col-span-2">
              <Logo className="h-auto" />

              <div className="text-white">
                With over 38 years of industrial experience and a legacy of
                innovation, WP Fusion is your trusted partner for cutting-edge
                polymer welding solutions.
              </div>
            </div>

            <div className="flex flex-col gap-2">
              <div className="text-white font-bold text-2xl">Quick Links</div>

              <FooterLink text="About" />
              <FooterLink text="Product Selector" />
              <FooterLink text="Contact Us" />
            </div>

            <div className="flex flex-col gap-2">
              <div className="text-white font-bold text-2xl">Social Media</div>

              <FooterLink text="LinkedIn" />
              <FooterLink text="Facebook" />
              <FooterLink text="Instagram" />
              <FooterLink text="Twitter" />
            </div>

            <div className="flex flex-col gap-2">
              <div className="text-white font-bold text-2xl">Contact</div>

              <div className="flex items-center gap-1.5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-5 h-5 text-primary"
                >
                  <path
                    fill-rule="evenodd"
                    d="M1.5 4.5a3 3 0 0 1 3-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 0 1-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 0 0 6.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 0 1 1.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 0 1-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5Z"
                    clip-rule="evenodd"
                  />
                </svg>

                <div className="text-white font-medium">+61 03 9798 7786</div>
              </div>

              <div className="flex items-center gap-1.5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-5 h-5 text-primary"
                >
                  <path d="M1.5 8.67v8.58a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3V8.67l-8.928 5.493a3 3 0 0 1-3.144 0L1.5 8.67Z" />
                  <path d="M22.5 6.908V6.75a3 3 0 0 0-3-3h-15a3 3 0 0 0-3 3v.158l9.714 5.978a1.5 1.5 0 0 0 1.572 0L22.5 6.908Z" />
                </svg>

                <div className="text-white font-medium">sales@wpfusion.com</div>
              </div>

              <div className="flex items-center gap-1.5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  class="w-5 h-5 text-primary"
                >
                  <path
                    fill-rule="evenodd"
                    d="m11.54 22.351.07.04.028.016a.76.76 0 0 0 .723 0l.028-.015.071-.041a16.975 16.975 0 0 0 1.144-.742 19.58 19.58 0 0 0 2.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 0 0-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 0 0 2.682 2.282 16.975 16.975 0 0 0 1.145.742ZM12 13.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
                    clip-rule="evenodd"
                  />
                </svg>

                <div className="text-white font-medium">
                  34 Sicily Road, Clyde VIC 3978 AU
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-between py-5 border-t border-white/10">
            <div className="text-white font-semibold">Privacy Policy</div>
            <div className="text-primary font-semibold">
              Copright &copy; 2024 | All Rights Reserved World Polymer Fusion
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
